/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import { PegasusClient } from '@brandedholdings/react-components';
import { Log } from '@brandedholdings/react-components/build/utils';
import * as queryString from '@brandedholdings/query-string';
import { siteMeta } from '../../data/site_meta.yml';

export class InterlincxContainerV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scriptLoaded: false,
            configLoaded: false,
            interlincxConfig: {},
        };
    }

    componentDidMount() {
        const client = new PegasusClient(siteMeta);
        const { useRawConfig } = this.props;

        if (useRawConfig) {
            const parsed = queryString.parse(location.search);
            client.getInterlincxDirectConfig(parsed.source_domain)
                .then((res) => {
                    this.setState({ interlincxConfig: res.data, configLoaded: true });
                })
                .catch(error => Log.debug(error));
        } else {
            client.getInterlincxConfig()
                .then((res) => {
                    this.setState({ interlincxConfig: res.data, configLoaded: true });
                })
                .catch(error => Log.debug(error));
        }
    }

    render() {
        const {
            scriptLoaded,
            configLoaded,
            interlincxConfig,
        } = this.state;
        const { onInterlincxClick, children } = this.props;

        return (
            <div>
                <Script
                    onError={() => { console.log('error'); }}
                    onLoad={() => this.setState({ scriptLoaded: true })}
                    url="https://adverse.lincx.la/ad"
                    attributes={{ 'data-manual-render': true }}
                />
                { scriptLoaded && configLoaded && children({ interlincxConfig, onInterlincxClick })}
            </div>
        );
    }
}

InterlincxContainerV2.propTypes = {
    onInterlincxClick: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    useRawConfig: PropTypes.bool,
};

InterlincxContainerV2.defaultProps = {
    useRawConfig: false,
};

export default InterlincxContainerV2;
