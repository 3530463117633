import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import {
    Form,
    Loader,
} from '@brandedholdings/react-components';
import { Log } from '../../../node_modules/@brandedholdings/react-components/build/utils';
import { siteMeta } from '../../data/site_meta.yml';

class TransferStep extends React.Component {
    static propTypes = {
        fields: PropTypes.object,
    }

    static endpoint = '/interstitial/submit';

    componentDidMount = () => {
        Log.toAnalyticsEvent('Form', 'Submit', 'Offer', true);
        // console.warn('INTERSTITIAL', this.props.interstitial.returnUrl);
        this.axios = axios.create({
            baseURL: siteMeta.pegasusBaseUrl,
            timeout: 45000,
        });

        Object.assign(this.axios.defaults, {
            withCredentials: true,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                websiteId: siteMeta.websiteId,
                pathname: (typeof window !== 'undefined' && window.location.pathname) || '',
                referrer: (typeof document !== 'undefined' && document.referrer) || '',
            },
        });
        const { fields } = this.props;
        const smsId = fields['smsOptIn.id'];
        const phone = fields['primaryPhone'] || null;
        const errorRedirect = '/lender/leadnetwork';
        const payload = {};

        if (phone) {
            payload.primaryPhone = phone.replace(/\D/g, '');
        }
        payload.smsOptIn = { id: smsId };
        payload.errorRedirect = errorRedirect;
        payload.meta = JSON.stringify({ sms_entity_id: 3 });

        this.axios
            .post(TransferStep.endpoint, payload)
            .then((response) => {
                try {
                    Log.debug('interstitial endpoint response', response);
                    if (typeof response.data.successRedirect === 'string') {
                        window.location = response.data.successRedirect;
                    }
                } catch (e) {
                    console.error('dispatch error', e);
                }
            })
            .catch((err) => {
                throw new Error(`Interstitial endpoint error: ${err}`);
            });
    };

    render() {
        return (
            <Form className="form">
                <div className="field">
                    <Loader inline message="Matching you with the best offers..." />
                </div>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        fields: state.applicantFields,
    };
}

export default connect(mapStateToProps)(TransferStep);
