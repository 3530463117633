import React from 'react';
import { connect } from 'react-redux';
import {
    Checklist,
    ChecklistItem,
    Form,
    PegasusPageView,
    utils,
} from '@brandedholdings/react-components';
import Field from '../FormField';
import PrepopulatedFields from '../../models/PrepopulatedFields';
import DefaultPageWrapper from '../DefaultPageWrapper';
import './default.scss';
import { siteMeta } from '../../data/site_meta.yml';

class OptInStep extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phoneMask: undefined, // utils.FieldFormats.formats.tel
        };
    }

    componentDidMount() {
        const { unwrap, siteMeta } = this.props;
        if (!unwrap) new PegasusPageView(siteMeta).postPageView();
    }

    formRefCallback = (ref) => {
        if (!ref) return;
        const { fields } = this.props;
        const { phoneMask } = this.state;

        ref.hydrate({
            ...fields,
            ...PrepopulatedFields.mapFields(),
        });

        if (!(this.state && phoneMask)) {
            this.setState({
                phoneMask: utils.FieldFormats.formats.tel,
            });
        }
    }

    render() {
        const { handleStepSubmit, initialValues, unwrap } = this.props;

        const smsId = siteMeta.sms.id || 6;

        return (
            <Form
                method="post"
                name="start-step"
                className="form"
                onSubmit={handleStepSubmit}
                initialValues={initialValues}
                ref={this.formRefCallback}
                unwrap={unwrap}
            >
                <div className="form-section">
                    <div className="step-content text-align--center">
                        <div className="split-form__prompt">
                            Get text updates with Finance Matrix!
                        </div>
                        <p className="split-form__content"><small>With Finance Matrix you can get your lender's information and other financial offers sent directly to your phone!</small></p>
                        <div className="field">
                            <label className="matrix--label">Mobile Phone Number</label>
                            <div className="field--container">
                                <Field
                                    type="tel"
                                    id="primaryPhone"
                                    name="primaryPhone"
                                    autoComplete="tel-national"
                                    mask="000-000-0000"
                                    placeholder="   -   -    "
                                />
                                { Field.renderFieldError('primaryPhone') }
                            </div>
                        </div>
                    </div>
                    <div className="step-sidebar">
                        <div className="step-sidebar__item">
                            <div className="split-form__prompt center">
                                Why should I opt in?
                            </div>
                            <Checklist vertical>
                                <ChecklistItem>
                                    Avoid the hassles of searching - Get the lender information sent directly to your mobile phone
                                </ChecklistItem>
                                <ChecklistItem>
                                    Get exclusive financial offers from Finance Matrix, tailored for your needs
                                </ChecklistItem>
                            </Checklist>
                        </div>
                    </div>
                </div>
                <div className="text-align--center">
                    <div className="opt-in--checkbox">
                        <Field
                            type="checkbox"
                            id="smsOptIn_id"
                            name="smsOptIn.id"
                            value={smsId}
                            label={<span className="opt-in--checkbox__heading">Yes! Send me the lender information by text message<br className="visible-desktop" /> &amp; exclusive alerts from Finance Matrix on financial offers.</span>} />
                            { Field.renderFieldError('smsOptIn.id') }
                    </div>
                    <p className="opt-in--disclaimer">
                        <small>
                            <span><strong>Text Message Opt In:</strong> By checking the box below, I verify that this is my mobile phone number and that I would like to receive recurring financial product or service offers via text messages (including but not limited to those using an automatic telephone dialing system or pre-recorded messages) at the number I have provided from {siteMeta.sms.company}, even if this number is listed on any federal or state do not call registry. I understand that I am not required to provide my consent as a condition of purchasing any products or services. Text STOP to stop and HELP for help to {siteMeta.sms.shortCode}. Message and data rates may apply; periodic changes to rates may occur. I also agree to the <a href="/privacy-policy" target="_blank">Privacy Policy</a>, including the Text Message Policy and <a href="/terms" target="_blank">>Terms & Conditions</a>. Carriers are not liable for delayed or undelivered messages.</span>
                        </small>
                    </p>
                    <p>
                        <small>
                            NOTE: Text message updates are
                            {' '}
                            <span className="underline">optional</span>
                            {' '}
                            and are in no way required to review on offer from a lender.
                        </small>
                    </p>
                    <p>
                        <button
                            type="submit"
                            className="button button--success button--big button--big__tablet">
                            Continue to Lender's Offer
                        </button>
                    </p>

                </div>
            </Form>
        );
    }
}

function mapStateToProps(state) {
    return {
        fields: state.applicantFields,
        fieldErrors: state.fieldErrors,
    };
}

export default connect(mapStateToProps, null)(OptInStep);
