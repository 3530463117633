import React from 'react';
import {
    Flow,
    PersonalLoanForm,
    Step,
} from '@brandedholdings/react-components';
import DataMap from '@brandedholdings/react-components/build/PegasusClient/DataMap';
import { Log } from '@brandedholdings/react-components/build/utils';
import OptInStep from './OptInStep';
import TransferStep from './TransferStep';

export default class Interstitial extends React.Component {
    static validate(vals) {
        const errors = {};

        // required field stuff
        const values = new DataMap(vals).toPegasus();

        if (values['smsOptIn.id'] && values.primaryPhone.length < 10) {
            errors.primaryPhone = 'Phone number should be exactly 10 characters.';
        }
        return errors;
    }

    constructor(props) {
        super(props);
        this.responseHandler = this.responseHandler.bind(this);

        this.flow = new Flow();
        this.flow.addStep(new Step({
            name: 'opt-in',
            type: OptInStep,
            nextStep: 'transfer',
            submitAction: 'interstitial/submit',
            responseHandler: this.responseHandler,
        }));
        this.flow.addStep(new Step({ name: 'transfer', type: TransferStep }));
    }

    responseHandler(applicantFields, pegasusClient, options) {
        let payload = { ...applicantFields };
        payload.meta = JSON.stringify({ sms_entity_id: 3 });
        pegasusClient.post(options.submitAction, payload, options.nextStep).then(response => {
            try {
                Log.debug('interstitial endpoint response', response);
                if (typeof response.data.successRedirect === 'string') {
                    window.location = response.data.successRedirect;
                }
            } catch(e) {
                Log.error("dispatch error", e);
            }
        })
        .catch(err => {
            throw new Error(`Interstitial endpoint error: ${err}`);
        });
    }

    render() {
        return (
            <PersonalLoanForm
                {...this.props}
                id="interstitial-form"
                className=""
                validate={Interstitial.validate}
                flow={this.flow}
                minLoanValue={0}
                maxLoanValue={1}
                modalDisclaimerText="FinanceMatrix.com is not a lender."
                enableGoogleStreet
            />
        );
    }
}
