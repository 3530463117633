/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Log } from '@brandedholdings/react-components/build/utils';

export class InterlincxDirectOffer extends Component {
    constructor(props) {
        super();
        this.primaryAdsRef = React.createRef();
    }

    async componentDidMount() {
        const { config: { zoneMappings, params }, zone } = this.props;
        Object.keys(params).forEach(key => params[key] === null && delete params[key]);
        Log.debug('InterlincxParameters', params);
        window.adverse(this.primaryAdsRef.current, {
            zoneId: zoneMappings[zone],
        });
        // TODO; Handle promise rejection (e.g. if there is a problem loading the zones)
        const timer = timeout => new Promise((resolve) => setTimeout(resolve, timeout));

        const ctas = await this.watchForInterlincxLoad();
        let a;
        for (let i = 0; i < ctas.length; i++) {
            const cta = ctas.item(i);
            a = cta.getElementsByTagName('a').item(0);
        }
        await timer(5000);
        a.click();
    }

    watchForInterlincxLoad = () => {
        return new Promise((resolve, reject) => {
            let counter = 0;
            const poller = setInterval(() => {
                const ctas = document.getElementsByClassName('offer__cta');
                if (ctas.length) {
                    clearInterval(poller);
                    resolve(ctas);
                } else if (++counter > 50) {
                    reject();
                }
            }, 100);
        });
    }

    render() {
        return (
            <div ref={this.primaryAdsRef} />
        );
    }
}

InterlincxDirectOffer.propTypes = {
    config: PropTypes.shape({
        horizon: PropTypes.shape({
            zoneId: PropTypes.string.isRequired,
        }),
        params: PropTypes.shape({}),
    }).isRequired,
    zone: PropTypes.string.isRequired,
};

export default InterlincxDirectOffer;
